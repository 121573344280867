import * as React from 'react';

export interface ButtonProps {
	type?: 'submit' | 'button';
	className?: string;
	isLoading?: boolean;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	title?: string;
	disabled?: boolean;
	other?: object;
	loadingNode?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = 
	({
		type = 'submit' ,
		className = 'btn btn-primary', 
		isLoading, 
		onClick, 
		children, 
		title, 
		disabled = false, 
		other = undefined, 
		loadingNode = false
	}) => {
		return <button 
			{...other} 
			type={type} 
			disabled={isLoading || disabled} 
			className={`button-component ${className} ${isLoading ? 'isLoading' : ''}`} 
			onClick={onClick}
			title={title}
		>
			{!!loadingNode && isLoading && loadingNode}
			<span>{children}</span>
		</button>;
	};

interface PreventDefaultButtonProps extends ButtonProps {
	onClick:() => void;
}

export const PreventDefaultButton: React.FC<PreventDefaultButtonProps> = ({onClick, children, ...rest}) => 
	<Button {...rest} type="button" onClick={(e) => { e.preventDefault(); e.stopPropagation(); onClick(); }}>
		{children}
	</Button>;

export default Button;