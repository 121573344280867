import React from 'react';
import {useTranslation} from 'react-i18next';

import ImageContainer from '@app/components/UI/ImageContainer/ImageContainer';

interface ExhibitionProps {
	text: any;
	title: string;
	imgType?: 0 | 1;
}

const Exhibition: React.FC<ExhibitionProps> = ({text, title, imgType}) => {
	return <div className="flex-row exhibition">
		<div className="exhibition__year p48">
			{text}
		</div>
		<div className="exhibition__name flex-column">
			<div className={`${imgType ? 'food' : 'prod'}-expo-container`}>
				<ImageContainer
					width={imgType ? 108 : 45}
					height={imgType ? 32 : 48}
					imageProps={{
						src: imgType ? '/images/FoodExpo.png' : '/images/ProdExpo.png',
						alt: imgType ? 'Food Expo' : 'Prod Expo'
					}}
				/>
			</div>
			<h2 className="section__subtitle">
				{title}
			</h2>
		</div>
	</div>;
};

const Exhibitions: React.FC = () => {
	const {t} = useTranslation();
	const exhibitions: ExhibitionProps[] = [
		{
			title: 'FoodExpo Qazaqstan WorldFood Kazakhstan',
			text: <>2018,<br/>{t('home.Astana')}</>,
			imgType: 1
		},
		{
			title: 'FoodExpo Qazaqstan WorldFood Kazakhstan',
			text: <>2019,<br/>{t('home.Almaty')}</>,
			imgType: 1
		},
		{
			title: 'ProdExpo Moscow',
			text: <>2021,<br/>{t('home.Moscow')}</>,
			imgType: 0
		},
		{
			title: 'ProdExpo Moscow',
			text: <>2022,<br/>{t('home.Moscow')}</>,
			imgType: 0
		}
	];

	return <div className="custom-container">
		<div className="flex-row exhibitions offset-right-2">
			{exhibitions.map((item, i) => <Exhibition {...item} key={i}/>)}
		</div>
	</div>;
};

export default Exhibitions;
