import * as Yup from 'yup';

import {WithDeleted} from '@common/typescript/objects/WithDeleted';

export const phoneRegexp = /(\(([0-9]{3})\)\s([0-9]{3})[-]([0-9]{4})|\+([0-9]{11}))/;

export const formattedPhoneRegexp = /^\+[1-9]+ \([1-9]\d{2}\) \d\d\d-\d\d\d\d$/;

export const stringOnlyLettersRegexp = /^[a-zA-Z]*$/;

export const simpleStringValidator = Yup.string().required();

export const stringOnlyLettersValidator = Yup.string().matches(stringOnlyLettersRegexp, 'Use only letters').required();

export const simpleNumberValidator = Yup.number().required();

export const positiveNumberValidator = Yup.number().positive('Required field!');

export const notEmptyPositiveNumberValidator = Yup.number().required().positive('Required field!');

export const notNullValidator = Yup.mixed().test('is-not-null', 'Required field!', value => value !== null);

export const notNullPositiveValidator = Yup.mixed().test('is-not-null', 'Required field!', value => value !== null && value >= 0);

export const emailValidator = Yup.string().email().required();

export const optionalEmailValidator = Yup.string().email().nullable().notRequired();

export const dateValidator = Yup.number().required().nullable();

export const phoneRequiredValidator = Yup.string().matches(phoneRegexp, 'Invalid phone number').required();

export const phoneValidator = Yup.string().test('is-valid', 'Invalid phone number', value =>
	!value || phoneRegexp.test(value));

export const formattedPhoneValidator = Yup.string().test('is-formatted-valid', 'Invalid phone number', value =>
	!value || formattedPhoneRegexp.test(value)
);

export const alphaDigitPasswordValidator = Yup.string().matches(/^([0-9a-zA-Z])+$/, 'Password should only contains digits and latin letters');

export const nonEmptyArray = (message: string) => Yup.array().test(
	'Non-empty array',
	message,
	(value: Array<WithDeleted> | undefined) => value ? value.some(v => !v.deleted) : false
);

export const lengthValidator = (maxLength, customMessage?) => Yup.string().max(maxLength, customMessage);

export const lengthRequiredValidator = (maxLength, customMessage?) => Yup.string().max(maxLength, customMessage).required();

export const minMaxNotNullValidator = (min, max) => Yup.mixed().test('min', `Must be >= ${min}`, value => value !== null && value >= min)
	.test('max', `Must be <= ${max}`, value => value !== null && value <= max);

export const minMaxValidator = (min, max) => Yup.mixed().test('min', `Must be >= ${min}`, value => value === null || value >= min)
	.test('max', `Must be <= ${max}`, value => value === null || value <= max);
