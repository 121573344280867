import React from 'react';

import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

interface Props {
	width: number;
	height: number;
	imageProps?: React.ImgHTMLAttributes<HTMLImageElement>;
	className?: string;
}

const ImageContainer: React.FC<Props> = ({width, height, imageProps, className = ''}) => {
	return <div
		className={`image-container ${className}`}
		style={{
			paddingTop: `${height / width * 100}%`
		}}
	>
		<ImageLazy {...imageProps} width={width} height={height}/>
	</div>;
};

export default ImageContainer;