import React from 'react';
import {useTranslation} from 'react-i18next';

import {Tabs} from 'antd';
import {TabsProps} from 'antd/lib/tabs';

import ImageContainer from '@app/components/UI/ImageContainer/ImageContainer';

interface Props {
}

interface LinkProps extends React.AllHTMLAttributes<HTMLAnchorElement> {
	withoutArrow?: boolean;
}

const Link: React.FC<LinkProps> = ({children, withoutArrow, className, ...rest}) => {
	return <a {...rest} className={`${className || ''} green-link`}>
		{children}
		{withoutArrow ? null :
			<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M2.969 17.1311L18.5501 1.55006M18.5501 1.55006V10.6502M18.5501 1.55006H9.44994"
					stroke="currentColor"
					strokeWidth="3"
					strokeLinecap="square"
				/>
			</svg>}
	</a>;
};

const TabImages: React.FC<{ images }> = ({images}) => {
	const [selectedIndex, setSelectedIndex] = React.useState(0);

	return <div className="tab-images">
		{images.map((image, index) =>
			<div
				key={image}
				onClick={() => setSelectedIndex(index)}
				className={index === selectedIndex ? 'selected-tab-image' : ''}
			>
				<ImageContainer
					width={480}
					height={380}
					imageProps={{
						src: image
					}}
				/>
			</div>
		)}
	</div>;
};

const Partners: React.FC<Props> = ({}) => {
	const {t, i18n} = useTranslation();
	const isRus = i18n.language === 'ru';

	const items: TabsProps['items'] = [
		{
			key: 'gn tobacco',
			label: <div style={{width: 144}}>
				<ImageContainer
					width={144}
					height={98}
					imageProps={{
						alt: 'GN Tobacco Sweden AB',
						title: 'GN Tobacco Sweden AB',
						src: '/images/tab1.png'
					}}
				/>
			</div>,
			children: <div>
				<div className="custom-container">
					<div className="flex-row offset-right-2" style={{marginBottom: 69}}>
						<div className="col-sm-6">
							<p>
								{isRus ? t('home.company') : null}
								<strong>{t('home.gnTobacco')}</strong>
								{t('home.GNTobaccoSwedenAB')}
							</p>
							<p>
								{t('home.TheCompanyHasStateOfTheArt')}
							</p>
							<Link href="https://gntobacco.com/" target="_blank">
								{t('home.GN Tobacco website')}
							</Link>
						</div>
						<div className="col-sm-6">
							<p>
								{t('home.TheTobaccoUsedByGNTobaccoSwedenAB')}
								{t('home.AfterAllThePreparatoryMeasures')}
							</p>
						</div>
					</div>
				</div>
				<TabImages
					images={['/images/GNTobacco1.png', '/images/GNTobacco2.png', '/images/GNTobacco3.png', '/images/GNTobacco4.png']}
				/>
			</div>
		},
		{
			key: 'v2tobacco',
			label: <div style={{width: 141}}><ImageContainer
				width={141}
				height={91}
				imageProps={{
					alt: 'v2 tobacco',
					title: 'V2 Tobacco',
					src: '/images/tab2.png'
				}}
			/></div>,
			children: <div>
				<div className="custom-container">
					<div className="flex-row offset-right-2">
						<div className="col-sm-6">
							<p>
								{isRus ? t('home.company') : null}
								<strong>{t('home.v2Tobacco')}</strong>
								{t('home.v2TobaccoText1')}
							</p>
							<p>
								{t('home.v2TobaccoText2')}
							</p>
							<Link href="https://www.swedishmatch.se/nyheter/v2-tobacco/" target="_blank">
								{t('home.V2 Tobacco website')}
							</Link>
						</div>
						<div className="col-sm-6">
							<p>
								{t('home.v2TobaccoText3')}
							</p>
							<p>
								{t('home.v2TobaccoText4')}
							</p>
						</div>
					</div>
				</div>
				<TabImages
					images={['/images/GNTobacco1.png', '/images/GNTobacco2.png', '/images/GNTobacco3.png', '/images/GNTobacco4.png']}
				/>
			</div>
		},
		{
			key: 'Scandinavian Tobacco Group',
			label: <div style={{width: 144}}><ImageContainer
				width={144}
				height={98}
				imageProps={{
					alt: 'Scandinavian Tobacco Group',
					title: 'Scandinavian Tobacco Group',
					src: '/images/tab3.png'
				}}
			/></div>,
			children: <div>
				<div className="custom-container">
					<div className="flex-row offset-right-2">
						<div className="col-sm-6">
							<p>
								{isRus ? t('home.company') : null}
								<strong>{t('home.spTobacco')}</strong>
								{t('home.spTobaccoText1')}
							</p>
						</div>
						<div className="col-sm-6">
							<p>
								{t('home.spTobaccoText2')}
							</p>
						</div>
					</div>
				</div>
				<TabImages
					images={['/images/GNTobacco1.png', '/images/GNTobacco2.png', '/images/GNTobacco3.png', '/images/GNTobacco4.png']}
				/>
			</div>
		},
		{
			key: 'mad monkerz',
			label: <div style={{width: 135}}><ImageContainer
				width={135}
				height={43}
				imageProps={{
					alt: 'Mad Monkeyz',
					title: 'Mad Monkeyz',
					src: '/images/tab4.png'
				}}
			/></div>,
			children:
				<div>
					<div className="custom-container">
						<div className="flex-row offset-right-2">
							<div className="col-sm-6">
								<p>
									{isRus ? t('home.company') : null}
									<strong>{t('home.madMonkeyz')}</strong>
									{t('home.madMonkeyzText')}
								</p>
								<Link href="https://www.madmonkeyz.ru/" target="_blank">
									{t('home.Mad Monkeyz website')}
								</Link>
							</div>
						</div>
					</div>
					<TabImages
						images={['/images/GNTobacco1.png', '/images/GNTobacco2.png', '/images/GNTobacco3.png', '/images/GNTobacco4.png']}
					/>
				</div>
		}
	];

	return (
		<Tabs items={items} defaultActiveKey="gn tobacco" className="mb-110"/>
	);
};

export default Partners;
