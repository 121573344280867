import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';

import once from 'lodash/once';
import { fetch } from 'domain-task';
import loadable from '@loadable/component';

import {loadableDelay} from '@common/react/loadable/loadableSettings';
import { BaseApplicationState } from '@common/react/store';
import { BaseUser } from '@common/typescript/objects/BaseUser';
import { BaseParams } from '@common/typescript/objects/BaseParams';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';
import { TypeKeys } from '@common/react/store/Login';

const params = {fallback: <Loader/>};

const ErrorPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ErrorPage" */ '@common/react/components/Pages/ErrorPage/ErrorPage')), params);

const AccessDenied = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AccessDenied" */
		'@common/react/components/Pages/AccessDenied/AccessDenied')), params);

const NotFound = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PageNotFound" */
		'@common/react/components/UI/PageNotFound/PageNotFound')), params);

export type RequestType = <T, >(type: string, data?: BaseParams) => Promise<T>;

interface ErrorComponents {
	accessDenied: React.JSXElementConstructor<{}>;
	notFound: React.JSXElementConstructor<{}>;
	errorPage: React.JSXElementConstructor<{message?: string}>;
}

export interface RequestProviderProps {
	errorHandlerForCustomCodes?: (ResponseError: ResponseError) => void;
	getErrorComponents?: (ResponseError: ResponseError, component: ErrorComponents) => React.ReactNode;
	accessDeniedComponent?: any;
	errorComponents?: Partial<ErrorComponents>;
}

export interface RequestProviderContextState {
	request: RequestType;
}

export interface RequestProviderContext {
	state: RequestProviderContextState;
}

export const createRequestProviderContext = once(() => React.createContext({} as RequestProviderContext));

export const useRequestProviderContext: () => RequestProviderContext = () => React.useContext(createRequestProviderContext());

interface Message<T> {
	success: number;
	response: T;
	session: string;
}

export enum ErrorCode
{
	NotStated = 0,
	NoRights = 1,
	UnspecifiedError = 42,
	NotFound = 65,
	CaptchaRequired = 66,
	TemporaryDisabled = 67
}

export interface ResponseError {
	message: string;
	code: number;
	path: string;
	isLogin?: boolean;
}

const defaultErrorComponents = {
	accessDenied: AccessDenied,
	notFound: NotFound,
	errorPage: ErrorPage
};

export const getDefaultErrorComponents = (error: ResponseError, components: ErrorComponents) => {
	const {
		accessDenied: AccessDeniedComponent,
		notFound: NotFoundComponent,
		errorPage: ErrorPageComponent
	} = components;
	switch (error.code)
	{
		case ErrorCode.NoRights:
			return <AccessDeniedComponent/>;
		case ErrorCode.NotFound:
			return <NotFoundComponent/>;
		case ErrorCode.UnspecifiedError:
			return <ErrorPageComponent message={error.message}/>;
		default:
			return null;
	}
};

export const RequestProvider: React.FC<RequestProviderProps> = ({children, ...props}) => {
	const {
		getErrorComponents = getDefaultErrorComponents,
		errorHandlerForCustomCodes = () => {},
		errorComponents = defaultErrorComponents
	} = props;

	const [errorComponent, setErrorComponent] = React.useState<any>(null);

	const ItemContext = createRequestProviderContext();

	const session = useSelector((state: BaseApplicationState<BaseUser>) => state.login.session, shallowEqual);
	const history = useHistory();
	const dispatch = useDispatch();

	const errorHandler = (error: ResponseError) => {
		if (error.code === ErrorCode.NoRights) {
			if (!error.isLogin) {
				dispatch({type: TypeKeys.CLEARSTATE});
				history.replace(error.path || '/');
				return;
			}
			if (error.path !== '/') {
				history.replace(error.path);
				return;
			}
		}

		const errorComponent = getErrorComponents(error, {...defaultErrorComponents, ...errorComponents});
		if (errorComponent) {
			setErrorComponent(errorComponent);
		} else {
			errorHandlerForCustomCodes(error);
		}

		console.log(error.message);
	};

	const request = React.useMemo(() => {
		return <T, >(type: string, data: BaseParams = {}): Promise<T> => {
			return fetch('api/post', {
				credentials: 'same-origin',
				method: 'POST',
				headers: {
					'Content-type': 'application/json; charset=utf-8',
					Cookie: `session=${session || ''}`
				},
				body: JSON.stringify({
					type,
					data: JSON.stringify(data)
				})
			})
				.then(response => response.json() as Message<T | ResponseError>)
				.then((data: Message<T | ResponseError>) => {
					if (!data.success) {
						throw data.response as ResponseError;
					}

					return data.response as T;
				})
				.catch((error: ResponseError) => {
					errorHandler(error);

					throw error.message as string;
				});
		};
	}, [session, getErrorComponents, history.location]);

	React.useEffect(() => {
		return history.listen((location, action) => {
			if (errorComponent) {
				setErrorComponent(null);
			}
		});
	}, [errorComponent]);

	const value = {
		state: {
			request
		}
	};

	return (
		<ItemContext.Provider value={value}>
			{errorComponent ? errorComponent : children}
		</ItemContext.Provider>
	);
};
