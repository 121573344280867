import React from 'react';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
	
}

const ImageLazy: React.FC<Props> = ({src, ...props}) => {
	const ref = React.useRef<HTMLImageElement | null>(null);
	const [visible, setVisible] = React.useState(false);

	React.useEffect(() => {
		if (ref.current) {
			const intersectionObserver = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					setVisible(true);
				}
			}, {
				rootMargin: '15px',
				threshold: 0.01
			});

			intersectionObserver.observe(ref.current);
			return () => intersectionObserver.disconnect();
		}
	}, [ref.current]);

	return <img ref={ref} src={visible ? src : ''} {...props} onError={visible ? props.onError : undefined}/>;
};

export default ImageLazy;
