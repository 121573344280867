import * as React from 'react';
import {Omit} from 'react-redux';

import { Field, FieldProps } from 'formik';

import {FormikInput, FormikInputProps, FormikInputRenderFunc} from '@common/react/components/Forms/FormikInput/FormikInput';

interface DefaultRenders {
	textarea: FormikInputRenderFunc;
}

const defaultRenders: DefaultRenders = {
	textarea: ({field}: FieldProps) => <textarea className="form-control" id={field.name} {...field} value={field.value === null ? '' : field.value}/>
};

export interface FormikFieldProps extends Omit<FormikInputProps, 'fieldProps'> {
	fieldName: string;
	defaultRender?: keyof DefaultRenders;
}

const FormikField: React.FC<FormikFieldProps> = (props) => {
	return (
		<Field name={props.fieldName}>
			{(fieldProps: FieldProps) =>
				<FormikInput
					{...props}
					fieldProps={fieldProps}
					render={props.defaultRender ? defaultRenders[props.defaultRender] : props.render}
				/>
			}
		</Field>
	);
};

export default FormikField;