import React from 'react';
import {useTranslation} from 'react-i18next';
import  MaskedInput from 'react-text-mask';

import {FieldProps, Formik, FormikHelpers} from 'formik';
import * as yup from 'yup';

import useRequest from '@common/react/hooks/useRequest';
import FormikField from '@common/react/components/Forms/FormikField/FormikField';
import {
	phoneRegexp
} from '@common/react/utils/validationHelpers';
import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';
import {phoneReplace} from '@common/react/components/Forms/FormikPhoneControl/FormikPhoneControl';
import Button from '@common/react/components/Forms/Button';

const PhoneMask = ['+', /\d/, ' ', /[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];

interface Props {
	title: string;
}

interface FormValues {
	name: string;
	phone: string;
	email: string;
	text: string;
}

const initialValues = {
	name: '',
	phone: '',
	email: '',
	text: '',
};

const Form: React.FC<Props> = ({title}) => {
	const {t} = useTranslation();
	const [message, setMessage] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState('');

	const validationSchema = yup.object().shape({
		text: yup.string(),
		name: yup.string().required(t('errors.Required field!')),
		phone: yup.string().matches(phoneRegexp, t('errors.Invalid phone number')).required(t('errors.Required field!')),
		email: yup.string().email(t('errors.Invalid email')).required(t('errors.Required field!'))
	});

	const request = useRequest();

	const handleSubmit = (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
		setError('');
		setMessage('');
		setLoading(true);

		Promise.all([
			request('inquiry', {
				name: values.name,
				email: values.email,
				text: values.text,
				phone: values.phone,
			}),
			new Promise((resolve, reject) => {
				setTimeout(() => resolve(''), 400);
			})
		])
			.then(() => {
				setMessage(t('forms.Заявка'));
				formikHelpers.resetForm({values: initialValues});
				setTimeout(() => setMessage(''), 15000);
				setLoading(false);
			})
			.catch((e) => {
				setError('Something went wrong');
				setTimeout(() => setLoading(false), 300);
			});
	};
	return <div className="contact-form" id="contact-us">
		{title ? <div className="contact-form__title">
			{title}
		</div> : null}
		<div className="custom-container">
			<div className="flex-row">
				<div className="col-sm-6">
					<h2 className="section__subtitle">
						{t('home.TheRepublicofKazakhstan')}
					</h2>
					<h2 className="section__subtitle">
						<a href="tel:+77474165677">
							+7 747 416 5677
						</a>
					</h2>
				</div>
				<div className="col-sm-6">
					<h5 className="section__subtitle" style={{textTransform: 'none'}}>
						{t('home.PleaseDoNotHesitateToContact')}
					</h5>
					<Formik<FormValues>
						initialValues={{
							name: '',
							phone: '',
							email: '',
							text: '',
						}}
						validationSchema={validationSchema}
						onSubmit={handleSubmit}
					>
						{formikBag => <form className={`is-relative ${message ? 'form-success' : ''}`}>
							<h5 className="success-message section__subtitle">
								<div>{t('forms.Заявка')}</div>
							</h5>
							<FormikField
								containerClassName="form-group"
								fieldName="name"
								render={({form, field}: FieldProps, inputProps?: React.HTMLProps<HTMLInputElement>) =>
									<div className="input-wrapper">
										<input
											placeholder={t('forms.name')}
											className="form-control" type="text" id={field.name} {...field} {...inputProps}
											onChange={e =>
												form.setFieldValue(field.name, e.currentTarget.value?.slice(0, 50))}
										/>
										<label htmlFor="name">
											<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M4.04492 3.95508C4.04492 2.05039 5.59531 0.5 7.5 0.5C9.40469 0.5 10.9551 2.05039 10.9551 3.95508C10.9551 5.85977 9.40469 7.41016 7.5 7.41016C5.59531 7.41016 4.04492 5.85977 4.04492 3.95508ZM8.37891 9.28906C9.76677 9.28906 11.0745 9.83944 12.0646 10.8448C13.0363 11.8314 13.576 13.1261 13.5915 14.5H1.40854C1.42396 13.1261 1.96374 11.8314 2.93536 10.8448C3.92554 9.83944 5.23326 9.28906 6.62109 9.28906H8.37891Z"
													stroke="currentColor"
												/>
											</svg>
										</label>
									</div>}
							/>
							<FormikField
								containerClassName="form-group"
								fieldName="phone"
								render={({form, field}: FieldProps, inputProps?: React.HTMLProps<HTMLInputElement>) =>
									<div className="input-wrapper">
										<MaskedInput
											mask={PhoneMask}
											className="form-control phone-input"
											type="text"
											guide
											placeholder="+_ ___ ___ __ __"
											id={field.name} {...field} {...inputProps}
											onChange={e => form.setFieldValue(field.name, phoneReplace(e.target.value))}
										/>
										<label htmlFor="phone">
											<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M10.9604 9.893L10.9604 9.89302L10.9649 9.89451C11.8711 10.194 12.8258 10.3458 13.7964 10.3458C14.1839 10.3458 14.5 10.6619 14.5 11.0494C14.5 11.0494 14.5 11.0494 14.5 11.0494V13.7964C14.5 14.1839 14.1839 14.5 13.7964 14.5C6.46512 14.5 0.5 8.53491 0.5 1.20363C0.5 0.816103 0.816103 0.5 1.20363 0.5H3.95832C4.34585 0.5 4.66194 0.816092 4.66191 1.20364V1.20367C4.66191 2.17353 4.81381 3.12642 5.11347 4.03548C5.19461 4.29767 5.12542 4.57019 4.93801 4.75734L4.91301 4.78231L4.89177 4.81055L3.65602 6.45321L3.4649 6.70726L3.61389 6.98811C4.69083 9.01817 5.96789 10.2942 8.0108 11.3847L8.29257 11.5351L8.5475 11.3427L10.2341 10.0695L10.2645 10.0465L10.2911 10.0192C10.3365 9.97263 10.4344 9.91264 10.5755 9.88103C10.7132 9.85017 10.8514 9.85581 10.9604 9.893Z"
													stroke="currentColor"/>
											</svg>
										</label>
									</div>}
							/>
							<FormikField
								containerClassName="form-group"
								fieldName="email"
								render={({form, field}: FieldProps, inputProps?: React.HTMLProps<HTMLInputElement>) =>
									<div className="input-wrapper">
										<input
											placeholder="e-mail*"
											className="form-control" type="text" id={field.name} {...field} {...inputProps}/>
										<label htmlFor="email">
											<svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M13.75 1.75C13.75 1.0625 13.1875 0.5 12.5 0.5H2.5C1.8125 0.5 1.25 1.0625 1.25 1.75M13.75 1.75V9.25C13.75 9.9375 13.1875 10.5 12.5 10.5H2.5C1.8125 10.5 1.25 9.9375 1.25 9.25V1.75M13.75 1.75L7.5 6.125L1.25 1.75"
													stroke="currentColor"
													strokeLinecap="round" strokeLinejoin="round"/>
											</svg>
										</label>
									</div>}
							/>
							<FormikField
								containerClassName="form-group"
								fieldName="text"
								render={({field, form}: FieldProps) => 
									<div className="input-wrapper">
										<textarea
											placeholder={t('forms.message')}
											className="form-control"
											id={field.name}
											{...field} value={field.value === null ? '' : field.value}
											onChange={e =>
												form.setFieldValue(field.name, e.currentTarget.value?.slice(0, 500))}
										/>
										<label>
											<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M11.9524 7.18997L11.9523 7.18992L11.9461 7.19612L6.48362 12.6586C6.43397 12.7083 6.40419 12.7256 6.38671 12.7334C6.3714 12.7402 6.34342 12.7497 6.28301 12.7497H2.95801C2.83413 12.7497 2.76976 12.7106 2.7376 12.6784C2.70545 12.6463 2.66634 12.5819 2.66634 12.458V9.13301C2.66634 9.06827 2.67728 9.02329 2.6908 8.99356C2.70238 8.96808 2.72064 8.94218 2.76036 8.9157L2.80155 8.88824L2.83656 8.85323L8.29906 3.39073L10.5157 1.17406C10.6366 1.05321 10.7941 1.05266 10.9153 1.17242L14.2378 4.57404L14.2378 4.57407L14.242 4.57823C14.3625 4.69881 14.3634 4.85589 14.2444 4.97697L11.9524 7.18997ZM1.37467 15.333H15.6247C15.7916 15.333 15.9163 15.4719 15.9163 15.6247C15.9163 15.7486 15.8772 15.8129 15.8451 15.8451C15.8129 15.8772 15.7486 15.9163 15.6247 15.9163H1.37467C1.2508 15.9163 1.18642 15.8772 1.15427 15.8451C1.12212 15.8129 1.08301 15.7486 1.08301 15.6247C1.08301 15.5008 1.12212 15.4364 1.15427 15.4043C1.18642 15.3721 1.2508 15.333 1.37467 15.333Z"
													stroke="currentColor"/>
											</svg>
										</label>
									</div>
								}
							/>
							<Button
								className={`green-button ${loading ? 'green-button-sending' : ''}${message ? 'green-button-success' : ''}`}
								type="button"
								disabled={loading || !!message}
								onClick={() => formikBag.submitForm()}
							>
								<ImageLazy className="message" tabIndex={-1} src="/images/sent.svg" width={45} height={45}/>
								<ImageLazy className="check" tabIndex={-1} src="/images/check.png" width={45} height={45}/>
								<br/>
								<span className="green-button__text">{t('forms.submit')}</span>
							</Button>
						</form>}
					</Formik>
				</div>
			</div>
		</div>
	</div>;
};

export default Form;