import * as React from 'react';
import {useTranslation} from 'react-i18next';

import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

import '@app/scss/pages/home.scss';
import AnimatedTitle from '@app/components/UI/AnimatedTitle/AnimatedTitle';
import ImageContainer from '@app/components/UI/ImageContainer/ImageContainer';
import Partners from '@app/components/Pages/Home/Partners';
import Exhibitions from '@app/components/Pages/Home/Exhibitions';
import Form from '@app/components/UI/Form/Form';

const Home: React.FC = () => {
	const {t, i18n} = useTranslation();

	return <div className="home-page">
		<div className="banner top-banner">
			<div className="image-block">
				<picture>
					<source media="(max-width: 768px)" srcSet="/images/topBackground-mobile.png"/>
					<source media="(min-width: 769px)" srcSet="/images/topBackground.png"/>
					<img className="banner__image" alt="banner" srcSet="/images/topBackground.png"/>
				</picture>
			</div>
			<div className="content-block">
				<div className="banner-text">
					<h2>
						{t('home.topBanner1')}
					</h2>
				</div>
			</div>
			<a className="green-button" href="#contact-us">
				<svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M64.0122 27.5C64.5004 24.5 63.5 22.8 60.5004 22.8H30.5C28 22.8 26.7122 25.4 26.0122 27.5M64.0122 27.5L57.0004 49.4C56.5004 51.5 54.59 53.2 52.5 53.2H22.8004C20.7104 53.2 18.5001 51.5 19.0004 49.4L26.0122 27.5M64.0122 27.5L41.2122 39.9L26.0122 27.5" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
					<line x1="9.25" y1="29.75" x2="15.75" y2="29.75" stroke="white" strokeWidth="2.5" strokeLinecap="round"/>
					<line x1="5.25" y1="35.75" x2="13.75" y2="35.75" stroke="white" strokeWidth="2.5" strokeLinecap="round"/>
					<line x1="1.25" y1="41.75" x2="11.75" y2="41.75" stroke="white" strokeWidth="2.5" strokeLinecap="round"/>
				</svg>
				<br/>
				<span className="green-button__text">{t('home.topButton')}</span>
			</a>
		</div>
		<div id="aboutUs"/>
		<AnimatedTitle>
			{t('home.aboutCompany')}
		</AnimatedTitle>
		<div className="custom-container about">
			<h4 className="section__subtitle offset-right-">
				{t('home.aboutCompanySubtitle1')}
			</h4>
			<div className="clearfix">
				<div className="flex-row">
					<div className="col-sm-6">
						<p>
							{t('home.largeAssortment4')}
						</p>
						<p>
							{t('home.largeAssortment1')}
						</p>
						<p>
							{t('home.largeAssortment2')}
						</p>
						<div className="offset-left-2 hide-m">
							<ImageContainer
								className="about-img-2"
								width={657}
								height={775}
								imageProps={{
									alt: 'about image 2',
									src: '/images/about2.png'
								}}
							/>
							<p>
								{t('home.largeAssortment3')}
							</p>
						</div>
					</div>
					<div className="col-sm-6 about-container-1">
						<ImageContainer
							className="about-img-1 image-offset-right"
							width={803}
							height={677}
							imageProps={{
								alt: 'about image 1',
								src: '/images/about1.png'
							}}
						/>
						<p style={{textTransform: 'uppercase'}}>
							{t('home.ourTeam')}
						</p>
						<h4 className="section__subtitle">
							{t('home.MoreThan15')}
						</h4>
						<div className="show-m">
							<ImageContainer
								className="about-img-2 image-offset-right"
								width={657}
								height={775}
								imageProps={{
									alt: 'about image 2',
									src: '/images/about2.png'
								}}
							/>
							<p>
								{t('home.largeAssortment3')}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="banner green-banner">
			<div className="image-block">
				<ImageLazy className="banner__image" alt="green banner" srcSet="/images/green-banner.png"/>
			</div>
			<div className="content-block">
				<div className="banner-text">
					<p style={{textTransform: 'uppercase'}}>
						{t('home.ourGoals')}
					</p>
					<h4 className="section__subtitle">
						{t('home.muchMoreThanJust')}
					</h4>
					<p className="banner__description">
						{t('home.ourGoalIsToEstablish')}
					</p>
				</div>
			</div>
		</div>
		<div className="custom-container" id="products">
			<p style={{textTransform: 'uppercase'}}>
				{t('header.products')}
			</p>
		</div>
		<AnimatedTitle offset={400}>
			{t('home.chewingTobacco')}
			<span className="title-badge">
				<span className="title-badge__text">
					<span>1</span>/3
				</span>
			</span>
		</AnimatedTitle>
		<div className="custom-container mb-110">
			<div className="flex-row">
				<div className="col-sm-6">
					<h5 className="section__subtitle">
						{t('home.whatIsThis')}
					</h5>
					<p className="offset-right-2">
						{t('home.oneOfTheTypesOfSmokeless')}
					</p>
					<ImageContainer
						className="chewing-tobacco-1 image-offset-left"
						width={946}
						height={558}
						imageProps={{
							alt: 'chewing Tobacco',
							src: '/images/chewingTobacco1.png'
						}}
					/>
				</div>
				<div className="col-sm-6 offset-right-2">
					<h5 className="section__subtitle">
						{t('home.howToUse')}
					</h5>
					<p>
						{t('home.aBagOfChewingTobaccoIsPlaced')}
					</p>
					<ImageContainer
						width={609}
						height={220}
						imageProps={{
							alt: 'chewing Tobacco types',
							src: '/images/chewingTobacco2.png'
						}}
						className="chewing-tobacco-2"
					/>
					<h5 className="section__subtitle">
						{t('home.advantages')}
					</h5>
					<p>{t('home.TheMainAdvantageOfChewingTobacco')}</p>
					<p>{t('home.YesItContainsNicotineBut')}</p>
				</div>
			</div>
		</div>
		<AnimatedTitle offset={400}>
			{t('home.hookahTobacco')}
			<span className="title-badge">
				<span className="title-badge__text">
					<span>2</span>/3
				</span>
			</span>
		</AnimatedTitle>
		<div className="custom-container mb-110">
			<div className="flex-row">
				<div className="col-sm-6">
					<h5 className="section__subtitle">
						{t('home.HowIsHookahTobaccoDifferent')}
					</h5>
					<p className="offset-right-1">
						{t('home.InTheManufactureOfCigarettes')}
					</p>
					<div className="block-with-image">
						<div className="image-wrapper" style={{'--w': 73, top: -15, '--t': '-10px'} as React.CSSProperties}>
							<div className="image-container">
								<ImageLazy
									alt={t('home.Муасель')}
									src="/images/block1.png"
									width={73}
									height={73}
								/>
							</div>
						</div>
						<h5 className="section__subtitle">
							{t('home.Муасель')}
						</h5>
						<p>
							{t('home.SuchADressingIncludesMolasses')}
						</p>
					</div>
					<div className="block-with-image">
						<div className="image-wrapper" style={{'--w': 111} as React.CSSProperties}>
							<div className="image-container">
								<ImageLazy
									alt={t('home.Томбак')}
									src="/images/block2.png"
									width={111}
									height={111}
								/>
							</div>
						</div>
						<h5 className="section__subtitle">
							{t('home.Томбак')}
						</h5>
						<p>
							{t('home.ThisIsPureClassicTobacco')}
						</p>
					</div>
					<div className="block-with-image">
						<div className="image-wrapper" style={{'--w': 132} as React.CSSProperties}>
							<div className="image-container">
								<ImageLazy
									alt={t('home.Журак')}
									src="/images/block3.png"
									width={132}
									height={132}
								/>
							</div>
						</div>
						<h5 className="section__subtitle">
							{t('home.Журак')}
						</h5>
						<p>
							{t('home.FinelyChoppedTobaccoLeaves')}
						</p>
					</div>
				</div>
				<div className="col-sm-6">
					<ImageContainer
						className="image-offset-right"
						width={806}
						height={802}
						imageProps={{
							alt: 'hookah tobacco',
							src: '/images/hookahTobacco.png'
						}}
					/>
				</div>
			</div>
		</div>
		<AnimatedTitle part={5.5}>
			{t('home.ProductsFromArmenia')}
			<span className="title-badge">
				<span className="title-badge__text">
					<span>3</span>/3
				</span>
			</span>
		</AnimatedTitle>
		<div className="custom-container mb-110 mobile-110">
			<div className="flex-row">
				<div className="col-sm-6">
					<ImageContainer
						width={945}
						height={621}
						imageProps={{alt: 'water', src: '/images/water.png'}}
						className="image-offset-left water-image"
					/>
					<div className="offset-left-2">
						<h5 className="section__subtitle">
							{t('home.AparanWater')}
						</h5>
						<p>
							{t('home.AparanIsOne')}
						</p>
						{
							i18n.language !== 'ru' ? <>
								<h5 className="section__subtitle">
									{t('home.OlivesSeliaAndAthena')}
								</h5>
								<p>
									{t('home.These are high-quality olives')}
								</p>
								<h5 className="section__subtitle">
									{t('home.CANNED VEGETABLES')}
								</h5>
								<p>
									{t('home.Ararat Kening Factory')}
								</p>
								<p>
									{t('home.At KPetrotorg, we are committed')}
								</p>
							</> : null
						}
					</div>
				</div>
				<div className="col-sm-6">
					<h5 className="section__subtitle">
						{t('home.WhatDoWeSupplyFromArmenia')}
					</h5>
					<ul className="home-list offset-right-2">
						<li>
							{t('home.AparanWater')}
						</li>
						<li>
							{t('home.OlivesSeliaAndAthena')}
						</li>
						<li>
							{t('home.CannedVegetables')}
						</li>
						<li>
							{t('home.tomatoPaste')}
						</li>
					</ul>
					<ImageContainer
						width={520}
						height={692}
						className="image-container-mobile"
						imageProps={{
							alt: 'canned food',
							src:'/images/image141.png'
						}}
					/>
				</div>
			</div>
		</div>
		<div className="our-partners" id="Partners">
			<AnimatedTitle>
				{t('home.OurPartners')}
			</AnimatedTitle>
		</div>
		<Partners/>
		<AnimatedTitle>
			{t('home.ParticipationInExhibitions')}
		</AnimatedTitle>
		<Exhibitions/>
		<Form title={t('home.companyName')}/>
	</div>;
};

export default Home;
