import React from 'react';

import gsap from 'gsap';

interface Props {
	offset?: number;
	part?: number; 
}

const AnimatedTitle: React.FC<Props> = ({children, offset = 300, part = 8}) => {
	const ref = React.useRef<HTMLHeadingElement>(null);

	React.useEffect(() => {
		if (ref.current) {
			gsap.to(ref.current, {
				scrollTrigger: {
					trigger: ref.current.closest('.custom-container'),
					start: () => `-${offset}px center`,
					end: () => `${offset}px center`,
					scrub: 1
				},
				ease: 'none',
				x: 0
			});
		}
	}, []);

	return <div className="custom-container section__title-wrapper">
		<h2 className="section__title section__title-animated" ref={ref}>
			{children}
		</h2>
	</div>;
};

export default AnimatedTitle;
