import React from 'react';

import { RequestType, useRequestProviderContext } from '@common/react/components/RequestProvider/RequestProvider';
import { request as baseRequest } from '@common/react/components/Api';
import { BaseParams } from '@common/react/objects/BaseParams';
import { BaseUser } from '@common/typescript/objects/BaseUser';
import { BaseApplicationState } from '@common/react/store';

const useRequest = (): RequestType => {
	const requestContext = useRequestProviderContext();

	const defaultRequest = React.useMemo(() => {
		return <T, >(type: string, data: BaseParams) => baseRequest<T, BaseUser, BaseApplicationState<BaseUser>>(type, data);
	}, []);

	return requestContext?.state?.request || defaultRequest;
};

export default useRequest;
